import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import {
    useParams,
} from "react-router-dom";
import { useState } from 'react';
import Header from './components/shared/Header';
import GlobalStateProvider from './context/GlobalStateContext';
import PageLoadingScreen from './components/PageLoadingScreen';
import { GameState } from './common/global-enums';
import { UserInfo, getUserInfo } from './common/user-info';
import { GlobalGameStats } from './common/global-game-stats';
import GameStats from './components/finished/GameStats';
import FinishedSummaryCard from './components/finished/FinishedSummaryCard';
import ErrorScreen, { ErrorScreenType } from './components/shared/ErrorScreen';
import Footer from './components/shared/Footer';
import { getGameResult } from './firebase/get-game-result-wrapper';
import TopAskedQuestions from './components/finished/TopAskedQuestions';
import { FrequentlyAskedQuestion } from './common/frequently-asked-question';
import { Hint } from './common/hint';

const GameResultsPage = (): React.ReactElement => {
    const { gameId } = useParams();

    const [isPageLoading, setIsPageLoading] = useState(true);
    const [secretWord, setSecretWord] = useState("");
    const [theme, setTheme] = useState("");
    const [dailyWordNumber, setDailyWordNumber] = useState(0);
    const [globalGameStats, setGlobalGameStats] = useState<GlobalGameStats>();
    const [faq, setFaq] = useState<FrequentlyAskedQuestion[]>([]);
    const [hints, setHints] = useState<Hint[]>([]);
    const [userInfo, setUserInfo] = useState<UserInfo>();
    const [errorScreenType, setErrorScreenType] = useState<ErrorScreenType>();

    const [enableSpoilerFreeMode, setEnableSpoilerFreeMode] = useState(true);
    const [enableAutoUpdates, setEnableAutoUpdates] = useState(false);
    const [numTimesAutoUpdated, setNumTimesAutoUpdated] = useState(0);

    const updateIntervalMs = 60000; // 1 min
    const maxNumTimesAutoupdated = 30; // 30 minutes

    if (isPageLoading) {
        getUserInfo().then((userInfo: UserInfo) => {
            getGameResult(gameId!, userInfo.getUserId()).then(gameResult => {
                setSecretWord(gameResult.secretWord);
                setTheme(gameResult.theme);
                setDailyWordNumber(gameResult.dailyWordNumber);
                setGlobalGameStats(gameResult.globalGameStats);
                setFaq(gameResult.faq);
                setHints(gameResult.hints);
                setUserInfo(userInfo);
                setIsPageLoading(false);
            }).catch((error) => {
                if (error.code === "functions/not-found") {
                    setErrorScreenType(ErrorScreenType.NOT_FOUND);
                } else {
                    setErrorScreenType(ErrorScreenType.GENERIC_ERROR);
                }
            });
        });
    }

    useEffect(() => {
        let interval: NodeJS.Timer | undefined = undefined;
        if (enableAutoUpdates) {
            interval = setInterval(() => {
                getGameResult(gameId!, userInfo!.getUserId()).then(gameResult => {
                    setNumTimesAutoUpdated(numTimesAutoUpdated + 1);
                    setGlobalGameStats(gameResult.globalGameStats);
                    setFaq(gameResult.faq);

                    if (numTimesAutoUpdated >= maxNumTimesAutoupdated) {
                        clearInterval(interval);
                        setEnableAutoUpdates(false);
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }, updateIntervalMs);
        } else if (interval) {
            clearInterval(interval);
        }

        return () => { if (interval) { clearInterval(interval); } }
    });

    return (
        <>
            {isPageLoading && errorScreenType === undefined && <PageLoadingScreen />}
            {
                !isPageLoading && errorScreenType === undefined &&
                <GlobalStateProvider
                    acceptedVariants={[]}
                    acceptedThemeWords={[]}
                    faq={faq}
                    gameId={gameId!}
                    globalGameStats={globalGameStats!}
                    secretWord={secretWord}
                    theme={theme}
                    dailyWordNumber={dailyWordNumber}
                    playerUserId={""}
                    initialGameState={GameState.NOT_STARTED}
                    initialGameTranscriptRecords={[]}
                    initialHints={hints}
                    initialGameSessionId={""}
                    initialUserInfo={userInfo!}
                    initialCurrentWinStreak={0}
                >
                    <Header />
                    <Container fluid="sm">
                        <div className="mt-4">
                            <FinishedSummaryCard
                                enableSettings={true}
                                enableSpoilerFreeMode={enableSpoilerFreeMode}
                                onSpoilerFreeModeChangedCallback={(enabled) => setEnableSpoilerFreeMode(enabled)}
                                enableAutoUpdates={enableAutoUpdates}
                                onAutoUpdatesChangedCallback={(enabled) => {
                                    setEnableAutoUpdates(enabled);
                                    if (enabled) {
                                        setNumTimesAutoUpdated(0);
                                    }
                                }
                                } />
                        </div>
                        <div className="mt-4">
                            <GameStats />
                        </div>
                        <div className="mt-4 mb-4">
                            <TopAskedQuestions enableSpoilerFreeMode={enableSpoilerFreeMode} />
                        </div>
                    </Container>
                    <Footer />
                </GlobalStateProvider>
            }
            {errorScreenType !== undefined && <ErrorScreen type={errorScreenType} />}
        </>
    );
}

export default GameResultsPage;
